import { forwardRef, useRef } from "react";
import { type ActionFunctionArgs, Link, useFetcher } from "react-router";
import * as mailer from "~/.server/services/mailer";
import { requireAppToken } from "~/.server/token";
import { Button } from "./ui/button";
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  StandaloneDialog,
  type StandaloneDialogRef,
} from "./ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Textarea } from "./ui/textarea";

export const DISCORD_INVITE_URL = "https://discord.gg/ey9BNNqyef";

function HelpIcon({ className }: { className?: string }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M10.2425 19.0555C10.2425 18.2578 10.8892 17.6111 11.6869 17.6111C12.4847 17.6111 13.1314 18.2578 13.1314 19.0555C13.1314 19.8533 12.4847 20.5 11.6869 20.5C10.8892 20.5 10.2425 19.8533 10.2425 19.0555Z"
        fill="currentColor"
      />
      <path
        d="M7.77753 6.86948C8.37754 4.5832 10.2416 3.39917 12.3287 3.50673C14.3901 3.61251 16.3084 4.74676 16.2195 7.37793C16.0933 11.1185 12.1314 10.6118 11.6878 14.1674H11.7021"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FeedbackIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" stroke="currentColor">
      <path d="M10.0002 13.3993L9.8355 13.3004L9.64696 13.3373C9.11414 13.4416 8.56435 13.5 8 13.5C3.79333 13.5 0.5 10.5292 0.5 7C0.5 3.47078 3.79333 0.5 8 0.5C12.2067 0.5 15.5 3.47078 15.5 7C15.5 8.62301 14.806 10.1124 13.6476 11.2633L13.5 11.4099V11.618V15.4989C13.4997 15.4988 13.4993 15.4987 13.4988 15.4984C13.4988 15.4984 13.4987 15.4983 13.4986 15.4983L10.0002 13.3993Z" />
    </svg>
  );
}

function DiscordIcon({ className }: { className?: string }) {
  return (
    <svg className={className} fill="none" stroke="currentColor">
      <path d="M13.206 3.268a12.406 12.406 0 0 0-3.094-.952c-.146.261-.279.53-.397.806a11.524 11.524 0 0 0-3.434 0 8.54 8.54 0 0 0-.396-.806c-1.07.182-2.11.503-3.097.954C.829 6.168.298 8.993.564 11.78a12.474 12.474 0 0 0 3.795 1.905c.307-.413.58-.851.813-1.31l.195-.398a8.915 8.915 0 0 0 5.266 0l.193.399c.233.459.505.897.813 1.31a12.417 12.417 0 0 0 3.797-1.905c.312-3.23-.532-6.03-2.23-8.512Zm-7.698 6.798c-.74 0-1.35-.671-1.35-1.497 0-.826.59-1.503 1.348-1.503.758 0 1.365.677 1.352 1.503-.013.826-.596 1.497-1.35 1.497Zm4.984 0c-.741 0-1.35-.671-1.35-1.497 0-.826.59-1.503 1.35-1.503.76 0 1.361.677 1.348 1.503-.013.826-.594 1.497-1.348 1.497Z" />
    </svg>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const token = await requireAppToken(request, { user: true });

  const formData = await request.formData();
  const feedback = formData.get("feedback");
  const from = token.user.name ? `${token.user.name} <${token.user.email}>` : token.user.email;
  console.log("Sharing feedback from", from);
  await mailer.sendEmail({
    to: "hey@sumcast.app",
    replyTo: from,
    subject: "Sumcast feedback",
    text: `${feedback}`,
  });
}

export const SendFeedbackDialog = forwardRef<StandaloneDialogRef, unknown>((_, ref) => {
  const fetcher = useFetcher();

  return (
    <StandaloneDialog ref={ref}>
      <DialogContent className="max-w-lg">
        <fetcher.Form method="post" action="/feedback">
          <DialogHeader>
            <DialogTitle>Share feedback</DialogTitle>
            <DialogDescription>
              We would love to hear from you! Please share what do you like and what we can improve.
            </DialogDescription>
          </DialogHeader>
          <Textarea name="feedback" placeholder="Your feedback" className="h-32 my-6" />
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="submit">Submit</Button>
            </DialogClose>
          </DialogFooter>
        </fetcher.Form>
      </DialogContent>
    </StandaloneDialog>
  );
});

export function HelpMenu() {
  const sendFeedbackDialogRef = useRef<StandaloneDialogRef>(null);

  return (
    <>
      <SendFeedbackDialog ref={sendFeedbackDialogRef} />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            type="button"
            className="ml-1 p-0 size-10 rounded-full flex items-center justify-center cursor-pointer text-header-foreground hover:bg-foreground/10 focusable focus-visible:ring-header-foreground"
          >
            <HelpIcon className="size-6" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuLabel>Get in touch</DropdownMenuLabel>
            <DropdownMenuItem onSelect={() => sendFeedbackDialogRef.current?.open()}>
              <FeedbackIcon className="size-4" />
              Share feedback
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link to={DISCORD_INVITE_URL}>
                <DiscordIcon className="size-4" />
                Discord
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
