import { Link } from "react-router";
import { SettingsMenu } from "~/pages/settings/menu";
import { useUser } from "~/pages/with-user";

function SignInButton() {
  return (
    <Link
      to="/signin"
      className="text-sm font-semibold border border-header-foreground/50 block px-4 py-1 rounded-md hover:bg-foreground/10 focusable"
    >
      Sign in
    </Link>
  );
}

export function Header({ children, showSignIn = true }: { children?: React.ReactNode; showSignIn?: boolean }) {
  const user = useUser();
  return (
    <div className="w-full bg-header-background text-header-foreground">
      <div className="flex flex-row items-center px-7 h-14">
        <Link
          to="/"
          className="mx-1 my-3 h-8 p-1 flex flex-row items-center rounded-full hover:bg-foreground/10 focusable focus-visible:ring-header-foreground"
        >
          <img src="/images/logo-light-24.svg" alt="Sumcast" />
        </Link>
        <div className="grow" />
        {children}
        {user ? <SettingsMenu key="settings" /> : showSignIn && <SignInButton key="signin" />}
        {/* <HelpMenu key="help" /> */}
      </div>
    </div>
  );
}
