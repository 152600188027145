import * as Lucide from "lucide-react";
import { useRef } from "react";
import { Link } from "react-router";
import { FeedbackIcon, SendFeedbackDialog } from "~/components/help";
import type { StandaloneDialogRef } from "~/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { SignOutItem } from "~/pages/auth/signout";
import { useUser } from "~/pages/with-user";
import { sidebarItems } from "./items";

export function SettingsMenu() {
  const sendFeedbackDialogRef = useRef<StandaloneDialogRef>(null);
  const user = useUser();
  if (!user) {
    return null;
  }

  return (
    <>
      <SendFeedbackDialog ref={sendFeedbackDialogRef} />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            type="button"
            className="rounded-full size-10 text-header-foreground p-0 hover:bg-foreground/10 flex items-center justify-center cursor-pointer focusable focus-visible:ring-header-foreground"
          >
            <Lucide.Menu className="size-6" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={() => sendFeedbackDialogRef.current?.open()}>
            <FeedbackIcon className="size-4" />
            Share feedback
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {user.onboarded && (
            <>
              {sidebarItems.map((item) => (
                <DropdownMenuItem asChild key={item.href}>
                  <Link to={item.href}>
                    <item.Icon className="size-4" />
                    {item.name}
                  </Link>
                </DropdownMenuItem>
              ))}
              <DropdownMenuSeparator />
            </>
          )}
          <SignOutItem />
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
